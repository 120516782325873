<template>
  <div class="flex-col page">
    <div class="flex-row items-center header space-x-12">
      <img
        class="image"
        src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/64d268765a7e3f0310ffb9e2/64d268d82a70980011693682/16919932617570583412.png"
      />
      <span class="text">UP少年</span>
    </div>
    <div class="flex-col flex-auto group">
      <div class="flex-col group_2 space-y-142">
        <div class="flex-row space-x-40">
          <div class="flex-row flex-auto">
            <div class="flex-col justify-start items-start flex-auto relative group_3">
              <img
                class="image_2"
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/64d268765a7e3f0310ffb9e2/64d268d82a70980011693682/16919944220986137635.png"
              />
              <div class="section_2 pos_2"></div>
              <img
                class="image_4 pos_3"
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/64d268765a7e3f0310ffb9e2/64d268d82a70980011693682/16919953673507305036.png"
              />
              <img
                class="image_3 pos"
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/64d268765a7e3f0310ffb9e2/64d268d82a70980011693682/16919932617589719151.png"
              />
            </div>
            <div class="flex-col self-start group_4">
              <span class="self-center text_2">UP少年</span>
              <span class="self-end font_1 text_3">孩子的体能训练管家</span>
              <div class="flex-col justify-start items-start relative group_5">
                <div class="section_3"></div>
                <div class="flex-col justify-start items-center image-wrapper pos_4">
                  <span class="self-end font_1 text_4">敬请期待~</span>
<!--                  QrCode -->
<!--                  <img-->
<!--                    class="shrink-0 image_5"-->
<!--                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/64d268765a7e3f0310ffb9e2/64d268d82a70980011693682/16919932628661598559.png"-->
<!--                  />-->
                </div>
                <div class="flex-row pos_5">
                  <div class="flex-row items-center button space-x-12">
                    <img
                      class="image_6 image_7"
                      src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/64d268765a7e3f0310ffb9e2/64d268d82a70980011693682/16919932628901915702.png"
                    />
                    <span class="font_2">IOS下载</span>
                  </div>
                  <div class="flex-row justify-center items-center button space-x-10">
                    <img
                      class="image_6"
                      src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/64d268765a7e3f0310ffb9e2/64d268d82a70980011693682/16919932632856594872.png"
                    />
                    <span class="font_2">Android下载</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="self-start section"></div>
        </div>
        <div class="flex-row justify-between items-end group_6">
          <div class="flex-col group_10 space-y-96">
            <div class="flex-col relative">
              <span class="self-center font_3 text_4">大数据</span>
              <span class="self-start font_1 text_5"><br /></span>
              <span class="font_1 pos_8">分析孩子能力数据</span>
            </div>
            <div class="flex-col relative group_11">
              <div class="self-start section_4"></div>
              <div class="self-end section_5"></div>
              <div class="group_9 pos_10"></div>
              <img
                class="image_9 pos_11"
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/64d268765a7e3f0310ffb9e2/64d268d82a70980011693682/16919953673531324867.png"
              />
              <img
                class="image_3 pos_9"
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/64d268765a7e3f0310ffb9e2/64d268d82a70980011693682/16919932617589719151.png"
              />
            </div>
          </div>
          <div class="flex-col group_7 space-y-344">
            <div class="flex-col justify-start items-start relative group_8">
              <div class="group_9 view"></div>
              <img
                class="image_8 pos_7"
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/64d268765a7e3f0310ffb9e2/64d268d82a70980011693682/16919953673476921881.png"
              />
              <img
                class="image_3 pos_6"
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/64d268765a7e3f0310ffb9e2/64d268d82a70980011693682/16919953673448856122.png"
              />
            </div>
            <div class="flex-col justify-start items-end relative">
              <div class="flex-col justify-start items-start text-wrapper">
                <span class="font_1 text_6">
                  <br />
                  实时同步
                </span>
              </div>
              <span class="font_3 pos_12">上课情况</span>
            </div>
          </div>
        </div>
        <div class="flex-row justify-between group_12">
          <div class="flex-col justify-start items-start self-start relative group_13">
            <div class="section_7"></div>
            <span class="font_3 pos_15">亲朋邻居</span>
            <span class="font_1 text_8 pos_17"><br /></span>
            <span class="font_1 text_7 pos_16">共同成长</span>
          </div>
          <div class="flex-col justify-start items-center relative section_6">
            <div class="section_2"></div>
            <img
              class="image_10 pos_14"
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/64d268765a7e3f0310ffb9e2/64d268d82a70980011693682/16919953673525744424.png"
            />
            <img
              class="image_3 pos_13"
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/64d268765a7e3f0310ffb9e2/64d268d82a70980011693682/16919932617589719151.png"
            />
          </div>
        </div>
      </div>
      <div class="flex-row justify-center section_8 space-x-10">
        <span class="font_4">Copyright© 2023深圳市沃栖汇科技发展有限公司</span>
        <span class="font_4">|</span>
        <span class="font_4" @click="openBeiAn" style="cursor: pointer"> 粤ICP备2023080103号-1</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {},
    data() {
      return {};
    },

    methods: {
      openBeiAn() {
        window.open('https://beian.miit.gov.cn/#/Integrated/index', '_blank');
      },
    },
  };
</script>

<style scoped lang="css">
  .page {
    background-color: #ffffff;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
  .header {
    padding: 1.88rem 10rem;
    background-color: #ffffff;
    filter: drop-shadow(0px 0.13rem 0.19rem #00000026);
    overflow: hidden;
    height: 6.75rem;
  }
  .image {
    border-radius: 0.63rem;
    width: 3rem;
    height: 3rem;
  }
  .text {
    color: #000000;
    font-size: 1.75rem;
    font-family: 阿里巴巴普惠体;
    font-weight: 800;
    line-height: 1.63rem;
  }
  .group {
    overflow-y: auto;
  }
  .group_2 {
    overflow: hidden;
  }
  .space-y-142 > *:not(:first-child) {
    margin-top: 8.88rem;
  }
  .space-x-40 > *:not(:first-child) {
    margin-left: 2.5rem;
  }
  .group_3 {
    padding-bottom: 26.31rem;
  }
  .image_2 {
    width: 30.88rem;
    height: 23.75rem;
  }
  .section_2 {
    background-color: #c71010;
    border-radius: 2.19rem;
    overflow: hidden;
    width: 20.44rem;
    height: 42.97rem;
  }
  .pos_2 {
    position: absolute;
    right: 0.84rem;
    top: 5.63rem;
  }
  .image_4 {
    background-color: #c71010;
    border-radius: 2.19rem;
    overflow: hidden;
    width: 20.44rem;
    height: 43rem;
  }
  .pos_3 {
    position: absolute;
    right: 0.84rem;
    bottom: 1.44rem;
  }
  .image_3 {
    width: 22.56rem;
    height: 45.56rem;
  }
  .pos {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .group_4 {
    margin-top: 11.75rem;
    width: 46.94rem;
  }
  .text_2 {
    color: #000000;
    font-size: 3.75rem;
    font-family: 微软雅黑 Semibold;
    line-height: 3.5rem;
  }
  .font_1 {
    font-size: 3rem;
    font-family: 微软雅黑 Semibold;
    line-height: 2.81rem;
    color: #000000;
  }
  .text_3 {
    margin-right: 4.13rem;
    margin-top: 2rem;
    line-height: 2.84rem;
  }
  .group_5 {
    margin-top: 2.38rem;
    padding: 2.63rem 0 0.5rem;
  }
  .section_3 {
    background-image: linear-gradient(298.5deg, #f7592733 6.3%, #f7592700 77.2%);
    border-radius: 50%;
    width: 18.31rem;
    height: 18.31rem;
  }
  .image-wrapper {
    padding: 0.88rem 0;
    background-color: #ffffff;
    overflow: hidden;
    width: 13.75rem;
    border: solid 0.063rem #cccccc;
  }
  .pos_4 {
    position: absolute;
    left: 15.56rem;
    top: 0;
  }
  .image_5 {
    width: 11.88rem;
    height: 11.88rem;
  }
  .pos_5 {
    position: absolute;
    right: 0;
    top: 17.25rem;
  }
  .button {
    padding: 1.38rem 0;
    background-image: linear-gradient(180deg, #f75927 0%, #f78228 100%);
    border-radius: 6.25rem;
    filter: drop-shadow(0px 0.25rem 0.38rem #fc861cbf);
    overflow: hidden;
    width: 15.25rem;
    height: 4.19rem;
  }
  .space-x-12 > *:not(:first-child) {
    margin-left: 0.75rem;
  }
  .image_6 {
    width: 1.5rem;
    height: 1.5rem;
  }
  .image_7 {
    margin-left: 4.31rem;
  }
  .font_2 {
    font-size: 1.25rem;
    font-family: 微软雅黑 Semibold;
    line-height: 1.16rem;
    color: #ffffff;
  }
  .section {
    background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/64d268765a7e3f0310ffb9e2/64d268d82a70980011693682/1d41d84f034df75fe0aba0c60384835a.png');
    filter: blur(0.75rem);
    background-size: 100% 100%;
    width: 14.88rem;
    height: 13.25rem;
  }
  .group_6 {
    padding-left: 16.31rem;
    padding-right: 3.38rem;
  }
  .group_10 {
    width: 37.38rem;
  }
  .space-y-96 > *:not(:first-child) {
    margin-top: 6rem;
  }
  .font_3 {
    font-size: 4.25rem;
    font-family: 微软雅黑 Semibold;
    line-height: 3.97rem;
    color: #f75927;
  }
  .text_4 {
    line-height: 4rem;
  }
  .text_5 {
    margin-left: 13rem;
    margin-top: 1.38rem;
  }
  .pos_8 {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .group_11 {
    padding-bottom: 20.94rem;
  }
  .section_4 {
    background-image: linear-gradient(298.5deg, #f7592733 6.3%, #f7592700 77.2%);
    border-radius: 50%;
    width: 31.75rem;
    height: 31.75rem;
  }
  .section_5 {
    margin-right: 1.5rem;
    margin-top: 4.44rem;
    background-image: linear-gradient(42.9deg, #f7592726 12%, #f7592714 108.5%);
    filter: blur(0px);
    border-radius: 50%;
    width: 9.69rem;
    height: 9.69rem;
  }
  .group_9 {
    border-radius: 2.19rem;
    overflow: hidden;
    width: 20.44rem;
    height: 42.97rem;
  }
  .pos_10 {
    position: absolute;
    right: 6.47rem;
    top: 22.34rem;
  }
  .image_9 {
    border-radius: 2.19rem;
    overflow: hidden;
    width: 20.19rem;
    height: 43rem;
  }
  .pos_11 {
    position: absolute;
    right: 6.72rem;
    bottom: 1.47rem;
  }
  .pos_9 {
    position: absolute;
    right: 5.63rem;
    bottom: 0;
  }
  .group_7 {
    margin-bottom: 0.25rem;
    width: 42.31rem;
  }
  .space-y-344 > *:not(:first-child) {
    margin-top: 21.5rem;
  }
  .group_8 {
    padding: 1.13rem 0 1.5rem;
  }
  .view {
    margin-left: 1.25rem;
  }
  .image_8 {
    border-radius: 2.19rem;
    overflow: hidden;
    width: 20.25rem;
    height: 43rem;
  }
  .pos_7 {
    position: absolute;
    left: 1.28rem;
    top: 50%;
    transform: translateY(-50%);
  }
  .pos_6 {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .text-wrapper {
    padding: 11.25rem 0 18.5rem;
    border-radius: 50%;
    background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/64d268765a7e3f0310ffb9e2/64d268d82a70980011693682/16919944220943910200.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 32.56rem;
  }
  .text_6 {
    margin-left: 0.13rem;
  }
  .pos_12 {
    position: absolute;
    left: 4.94rem;
    top: 6.13rem;
  }
  .group_12 {
    padding-left: 21.19rem;
  }
  .group_13 {
    margin-top: 15.69rem;
    padding-bottom: 1.5rem;
    width: 22.84rem;
  }
  .section_7 {
    background-image: linear-gradient(42.9deg, #f7592733 12%, #f759271c 108.5%);
    filter: blur(1.53rem);
    border-radius: 50%;
    width: 9.69rem;
    height: 9.69rem;
  }
  .pos_15 {
    position: absolute;
    right: 0;
    top: 2.97rem;
  }
  .text_8 {
    line-height: 2.75rem;
  }
  .pos_17 {
    position: absolute;
    left: 9rem;
    bottom: 0;
  }
  .text_7 {
    line-height: 2.75rem;
  }
  .pos_16 {
    position: absolute;
    right: 1.56rem;
    bottom: 0;
  }
  .section_6 {
    padding: 0.88rem 0 18rem;
    background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/64d268765a7e3f0310ffb9e2/64d268d82a70980011693682/16919944221009325793.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 66.63rem;
    height: 61.75rem;
  }
  .image_10 {
    background-color: #c71010;
    border-radius: 2.19rem;
    overflow: hidden;
    width: 20.38rem;
    height: 43rem;
  }
  .pos_14 {
    position: absolute;
    left: 22.22rem;
    top: 0.84rem;
  }
  .pos_13 {
    position: absolute;
    left: 20.94rem;
    top: -0.25rem;
  }
  .section_8 {
    padding: 0.88rem 0 0.63rem;
    background-color: #f75927;
    overflow: hidden;
  }
  .space-x-10 > *:not(:first-child) {
    margin-left: 0.63rem;
  }
  .font_4 {
    font-size: 0.88rem;
    font-family: 微软雅黑;
    line-height: 0.97rem;
    color: #ffffff;
  }
</style>
