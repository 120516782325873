import Vue from 'vue';
import VueRouter from 'vue-router';
import Up from '../pages/up/up.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'up',
    component: Up,
    meta: {
      title: 'UP少年',
    }
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
